import { toggleModal } from '@/components/ModalComponent.vue';
import { ISelectOption } from '@/interfaces';
import { ECategorySelectType } from '@/services/CategorySevice';
import { randomString } from '@/utils/Helper';
import { PropType, defineComponent } from 'vue';

export interface IAutocompleteSelectDataResponse {
  value: ISelectOption,
  isNew: boolean
}
export default defineComponent({
  name: 'AutocompleteInput',
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Selecione'
    },
    errorMsg: {
      type: String,
      default: 'Por favor, preencha este campo corretamente'
    },
    inputStyle: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    dataSet: {
      type: Array as () => ISelectOption[],
      default: []
    },
    selectedOption: {
      type: [String, Number],
      default: null
    },
    validationModel: {
      required: false,
      default: () => { }
    },
    itemCreationType: {
      type: Array as unknown as PropType<ECategorySelectType>,
      default: [],

    },

  },

  watch: {
    selectedOption(value) {

    }
  },
  mounted() {
    this.setupSelectedOption(`${this.selectedOption}`)
  },
  data() {
    return {
      modalId: `${randomString()}_new_item`,
      searchString: '',
      model: { id: '', title: '' } as ISelectOption,
      showResults: false,
      newItemTitle: '',
      submittingNewItem: false,
      filteredResults: [] as any[]
    }
  },
  methods: {
    setupSelectedOption(value: string) {
      this.model = this.dataSet.find((elem: ISelectOption) => elem.id == value) || { id: '', title: '' }
      if (this.model) {
        this.searchString = `${this.model.title}`
      }
    },
    handleItemSelected(item: any) {
      if (item.id) {
        this.searchString = `${item.title}`
      }
      this.model = item
      this.$emit('modelChanged', {
        value: {
          id: item.id,
          title: item.title
        },
        isNew: false
      } as IAutocompleteSelectDataResponse)
    },
    hideResults() {
      setTimeout(() => {
        this.showResults = false
        if (this.searchString) {
          const t = JSON.parse(JSON.stringify(this.model))
          this.searchString = `${t.title}`
        } else {
          this.handleItemSelected({ id: '', title: '' })
        }

      }, 300);
    },

    handleSearch() {
      const originArray = this.dataSet as any[]
      const string = this.searchString
      const keys = ['title']
      let fResults = [] as any[]
      try {
        let properties = keys
        if (!string) {
          originArray.map(r => r.hidden = false)
          fResults = originArray
        }
        if (!originArray || originArray.length == 0) return originArray
        if (!properties || properties.length == 0) {
          properties = Object.keys(originArray[0])
        }
        var resultArray = originArray
        resultArray.map(item => {
          let reg = /[\u0300-\u036f]/g
          var itemFound = false
          properties.map((s: any) => {
            if (item[s] && ['string', 'number'].includes(typeof item[s])) {
              if (item[s].toString().toLowerCase().normalize('NFD').replace(reg, '').indexOf(string.toLowerCase().normalize('NFD').replace(reg, '')) > -1) {
                itemFound = true
              }
            }
          })
          item.hidden = !itemFound
        })
        fResults = resultArray
      } catch (e) {
        console.log(e)
        fResults = originArray
      } finally {
        this.filteredResults = fResults.filter((elem) => !elem.hidden)
      }
    },
    async handleCreateNewItem() {
      // cria o novo item
      try {
        this.submittingNewItem = true
        const response: any = await this.$categoryService.create(this.itemCreationType, this.newItemTitle)
        console.log(response)
        this.$emit('modelChanged', {
          value: response,
          isNew: true
        } as IAutocompleteSelectDataResponse)
        this.searchString = response.title
        toggleModal(this.modalId)
      } catch (e) {
        console.log(e)
      } finally {
        this.submittingNewItem = false
      }
    },
    clearValue() {
      this.model = { id: '', title: '' }
      this.searchString = ''
    },
    openNewItemModal() {
      this.newItemTitle = this.searchString
      toggleModal(this.modalId)

      // abre o modal pra criar um novo item
    }

  }
})

