
import moment from 'moment'
import NetworkService, { Endpoint } from './NetworkService'
import { ISelectOption } from '@/interfaces'

export interface ICalculateIntallmentFeeParams {
  value: number
  installments: number
}

export default class OrderService {
  network
  constructor() {
    this.network = new NetworkService()
  }

  static installmentOptions: ISelectOption[] = Array(12).fill('').map((elem: any, index: number) => {
    return index == 0 ? {
      id: '1',
      title: 'Somente à vista'
    } : {
      id: (index + 1).toString(),
      title: `Até ${index + 1} vezes`
    }
  })
  static validityOptions: ISelectOption[] = [
    {
      id: null, title: 'Indefinido',
    },
    {
      id: 365, title: '1 ano',
    },
    {
      id: 730, title: '2 anos',
    },
  ]
  static validityValues: { [key: string]: { title: string } } =
    {
      "365": { title: '1 ano' },
      "730": { title: '2 anos' }
    }
  list = (params: any) => {
    return this.network.get(Endpoint.simulationOrders, params)
  }
  listInstallments = async (params: ICalculateIntallmentFeeParams) => {
    const res: any = await this.network.get(Endpoint.calculateInstallmentFee, params)

    res.formatted_value = Number(res.value).toCurrency();
    res.formatted_amount_to_receive = res.amount_to_receive.toCurrency();
    res.formatted_intallment_value = (
      Number(res.value) / Number(res.installments)
    ).toCurrency();
    res.formatted_intallment_value_to_receive =
      res.installments_value.toCurrency();
    res.formatted_fee = res.fee.toCurrency();

    return res

    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve({
    //       status: true,
    //       data: Array(installments).fill('').map((elem: any, index: number) => {
    //         return `${index + 1}x de R$${(value / (index + 1)).toFixed(2).replace('.', ',')}`
    //       })
    //     });
    //   }, 3000);
    // });
    // return this.network.get(Endpoint.simulationOrders, params)
  }
  show = (id: string) => {
    return this.network.get(`${Endpoint.simulationOrders}/${id}`)
  }
  prepareExam = (data: any) => {
    try {
      data.formatted_created_at = moment(data.created_at).format('DD/MM/YYYY')
      data.formatted_status = data.status.capitalizeFirst()
      if (data.payment_method) {
        data.formatted_payment_method = data.payment_method == 'credit_card' ? 'Cartão de crédito' : 'Pix'
      }
    } catch (e) {
      console.log(e)
    } finally {
      return data
    }
  }
}
