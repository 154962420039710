import axios from 'axios'
import router from '@/router'
import paths from '@/router/paths'
import qs from 'qs'
import { store } from '@/store'

export default interface IApiResponse {
  status: boolean,
  data: any,
  access_token?: string,
  next_page_url?: string,
  per_page?: number,
  total?: number,
}
export enum Endpoint {
  login = 'login',
  users = 'users',
  usersAdmin = 'users-admin',
  banners = 'landing/featured-items',
  bannersUpdate = 'landing/featured-items-update/',
  mockExams = 'products/simulations',
  updatePassword = 'users/updatePassword',
  homeInfo = 'utils/home-info',
  examCategories = 'products/simulations-categories',
  simulationOrders = 'my-simulations',
  settings = 'settings',
  questionCategories = 'question-category',
  appeals = 'appeals',
  testimony = 'testimony',
  annulQuestion = 'invalidate-question',
  allCategories = 'all-categories',
  levels = 'levels',
  institutes = 'institutes',
  jobTitles = 'offices',
  boards = 'benches',
  addExamPdf = 'add-exampdf',
  deleteExamPdf = 'delete-exampdf',
  addResolutionPdf = 'add-resolutionspdf',
  deleteResolutionPdf = 'delete-resolutionspdf',
  updateExam = 'products-update/simulations',
  groups = 'bundle',
  groupsUpdate = 'bundle-update',
  upcomingExams = 'simulation-coming-soon',
  removeUpcomingExams = 'remove-bundle-simulation-coming-soon',
  removeGroupBonusPdf = 'remove-bonus-pdf',
  removeGroupExam = 'remove-bundle-simulation',
  approved = 'approveds',
  generalTestimonials = 'general-testimonials',
  generalTestimonialsUpdate = 'general-testimonials-update',
  deleteGroupVideo = 'delete-youtube_video',
  groupVideo = 'youtube_video',
  calculateInstallmentFee = 'payment/calculateInstallmentFee'
}
export default class NetworkService {
  baseUrl = process.env.VUE_APP_API_BASE_URL
  constructor() { }
  post<T>(endPoint: Endpoint, params: T): Promise<IApiResponse> {
    return axios.post(this.baseUrl + endPoint, params, { headers: this.headers() })
      .then((res) => this.handleResponse(res))
      .catch(e => { this.handleError(e) })
  }
  get(endPoint: Endpoint | string, query: any = {}, defaultToken = ''): Promise<IApiResponse> {
    if (query.search) {
      query.q = query.search.trim()
    }
    if (query.orderBy && query.orderBy.length) {
      // query.orderBy = `${query.orderBy[0].sortName}|${query.orderBy[0].order}`
      query.orderByDirection = query.orderBy[0].order // asc ou desc
      query.orderBy = query.orderBy[0].sortName // nome da coluna
    }
    delete (query.search)
    const url = `${this.baseUrl}${endPoint}?${qs.stringify(query)}`

    return axios.get(url, { headers: this.headers('', defaultToken) })
      .then((res) => this.handleResponse(res))
      .catch(e => { this.handleError(e) })
  }

  put<T>(endPoint: Endpoint | string, params: T): Promise<IApiResponse> {
    return axios.put(this.baseUrl + endPoint, params, { headers: this.headers() })
      .then((res) => this.handleResponse(res))
      .catch(e => { this.handleError(e) })
  }

  delete(endPoint: Endpoint | string): Promise<IApiResponse> {
    return axios.delete(this.baseUrl + endPoint, { headers: this.headers() })
      .then((res) => this.handleResponse(res))
      .catch(e => { this.handleError(e) })
  }

  postEncoded<T>(endPoint: Endpoint, params: T): Promise<IApiResponse> {
    return axios.post(this.baseUrl + endPoint, qs.stringify(params), { headers: this.headers('urlencoded') })
      .then((res) => this.handleResponse(res))
      .catch(e => { this.handleError(e) })
  }

  putEncoded<T>(endPoint: Endpoint, params: T): Promise<IApiResponse> {
    return axios.put(this.baseUrl + endPoint, qs.stringify(params), { headers: this.headers('urlencoded') })
      .then((res) => this.handleResponse(res))
      .catch(e => { this.handleError(e) })
  }

  postMultipart<T>(endPoint: Endpoint | string, params: T): Promise<IApiResponse> {
    return axios.post(this.baseUrl + endPoint, this.makeItMultipartParams(params), { headers: this.headers('multipart') })
      .then((res) => this.handleResponse(res))
      .catch(e => { this.handleError(e) })
  }

  putMultipart<T>(endPoint: Endpoint | string, params: T): Promise<IApiResponse> {
    return axios.put(this.baseUrl + endPoint, this.makeItMultipartParams(params), { headers: this.headers('multipart') })
      .then((res) => this.handleResponse(res))
      .catch(e => { this.handleError(e) })
  }


  makeItMultipartParams(params: any): FormData {
    const p = new FormData()
    Object.keys(params).filter((key: string) => params[key] !== null).forEach(function (key, index) {
      if (Array.isArray(params[key])) {
        params[key].forEach(function (obj: any, index: number) {
          if (obj instanceof File || (!(obj instanceof File) && typeof obj != 'object')) {
            // params[key].map((r: any) => {
            p.append(`${key}[]`, obj)
            // })
          } else {
            Object.keys(obj).forEach(function (arrayKey) {
              p.append(`${`${key}`}[${index}][${arrayKey}]`, obj[arrayKey]);
            });
          }
        });
      } else {
        p.append(key, params[key])
      }
    })
    console.log('\n----\n');
    for (var pair of p.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    return p
  }

  makeExternalRequest(type: string, endPoint: string, params: any, headers = {}) {
    switch (type) {
      case 'post':
        return axios.post(endPoint, params, { headers })

      case 'get':
        return axios.get(endPoint, { headers })

      case 'put':
        return axios.put(endPoint, params, { headers })
    }
  }

  headers(encodeType = '', defaultToken = '') {
    const access_token = store.state.token || defaultToken
    const headers: any = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (encodeType == 'multipart') {
      delete (headers['Content-Type'])
    }
    if (encodeType == 'urlencoded') {
      headers['Content-Type'] = 'application/x-www-form-urlencoded'
    }
    if (access_token) {
      headers.Authorization = `Bearer ${access_token}`
    }
    return headers
  }

  handleResponse(res: any) {
    if (res.data.status === false) {
      this.handleError(res)
    } else {
      return res.data
    }
  }

  handleError(error: any) {
    const e = error.response || error
    console.log('ERRRRRROOOOOOOOOOOOOOOO', e)
    if (e && e.status && e.status == 401) {
      router.push(paths.login)
    }
    let message = e?.data?.message || e?.message || 'Ocorreu um erro, tente novamente'
    if (e?.data?.errors) {
      const keys = Object.keys(e.data?.errors)
      message = e.data?.errors[keys[0]][0]
      console.log('meeeeeeeessage', message)
    }
    throw ({ message })
  }
}
