
import { IBannerPost, ISelectOption } from '@/interfaces'
import NetworkService, { Endpoint } from './NetworkService'
import IBannerUpdate from '@/interfaces/IBannerUpdate'
import moment from 'moment'
import { IGroupPost, IUpcomingExamPost } from '@/interfaces'
import { IGroupVideoResponse } from '@/components/GroupVideosAddComponent/GroupVideosAddComponentController'

export enum EGroupStatus {
  published = 'publicado',
  unpublished = 'não listado'
}

export default class GroupService {
  network
  constructor() {
    this.network = new NetworkService()
  }
  static statusOptions: ISelectOption[] = [
    {
      id: EGroupStatus.published,
      title: 'Publicado'
    },
    {
      id: EGroupStatus.unpublished,
      title: 'Não listado'
    },
  ]
  getFormattedStatus(status: string) {
    switch (status) {
      case EGroupStatus.published:
        return {
          title: 'Publicado',
          color: 'text-success'
        }
      case EGroupStatus.unpublished:
        return {
          title: 'Não listado',
          color: 'text-warning'
        }
      default:
        return {
          title: '',
          color: ''
        }
    }
  }
  list = (params: any) => {
    return this.network.get(Endpoint.groups, params)
  }
  show = (id: string) => {
    return this.network.get(`${Endpoint.groups}/${id}`)
  }
  create = (params: IGroupPost) => {
    return this.network.postMultipart(`${Endpoint.groups}`, params)
  }
  update = (id: string | number, params: any) => {
    return this.network.postMultipart(`${Endpoint.groupsUpdate}/${id}`, params)
  }
  createBonusPdf = (group_id: string, files: File) => {
    return this.network.postMultipart(`${Endpoint.groupsUpdate}/${group_id}`, { bonus_pdf: [files] })
  }
  deleteGroupBonusPdf = (id: string) => {
    return this.network.delete(`${Endpoint.removeGroupBonusPdf}?bonus_pdf_id=${id}`)
  }
  addUpcomingExam = (params: IUpcomingExamPost) => {
    return this.network.post(Endpoint.upcomingExams, params)
  }
  listUpcomingExams = (params: IUpcomingExamPost) => {
    return this.network.get(Endpoint.upcomingExams, params)
  }
  deleteUpcomingExam = (group_id: string, upcoming_exam_id: string) => {
    return this.network.delete(`${Endpoint.removeUpcomingExams}/${group_id}?simulation_coming_soon_id=${upcoming_exam_id}`)
  }
  deleteGroupExam = (group_id: string, exam_id: string) => {
    return this.network.delete(`${Endpoint.removeGroupExam}/${group_id}?simulation_id=${exam_id}`)
  }
  deleteGroupVideo = (id: string) => {
    return this.network.delete(`${Endpoint.deleteGroupVideo}/${id}`)
  }
  updateGroupVideo = (params: IGroupVideoResponse) => {
    return this.network.put(`${Endpoint.groupVideo}/${params.id}`, params)
  }
  prepareGroup = (item: any) => {
    try {
      item.formatted_created_at = moment(item.created_at).format('DD/MM/YYYY')
      item.status_object = this.getFormattedStatus(item.status)
      item.formatted_image = item.image || require('@/assets/img/group_placeholder.png')
      item.formatted_price = !item.price ? 'Gratuíto' : item.price.toCurrency()
      if (item.bonus_pdf?.length) {
        item.bonus_pdf = item.bonus_pdf.map((elem: any) => {
          let name = elem.url.split('/')
          name = name[name.length - 1]
          return {
            ...elem,
            name
          }
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      return item
    }
  }
}
