import ConfirmationAlert from '@/components/ConfirmationAlert/ConfirmationAlert.vue'
import Container from '@/components/Container.vue'
import EmptyState from '@/components/EmptyState.vue'
import SimpleEmptyState from '@/components/SimpleEmptyState.vue'
import FormButton from '@/components/FormComponents/FormButton.vue'
import EditorInput from '@/components/FormComponents/EditorInput.vue'
import PasswordInput from '@/components/FormComponents/PasswordInput.vue'
import SelectInput from '@/components/FormComponents/SelectInput.vue'
import TextAreaInput from '@/components/FormComponents/TextAreaInput.vue'
import TextInput from '@/components/FormComponents/TextInput.vue'
import AutocompleteInput from '@/components/FormComponents/AutocompleteInput/AutocompleteInput.vue'
import AutocompleteSelectInput from '@/components/FormComponents/AutocompleteSelectInput/AutocompleteSelectInput.vue'
import ImageCropper from './components/ImageCropper/ImageCropper.vue'
import FileUploader from './components/FileUploader/FileUploader.vue'
import YoutubeInput from './components/YoutubeInput/YoutubeInput.vue'
import Header from '@/components/Header.vue'
import HomeCard from '@/components/HomeCard.vue'
import LoadingContainer from '@/components/LoadingContainer.vue'
import ModalComponent from '@/components/ModalComponent.vue'
import Navbar from '@/components/Navbar.vue'
import SearchBar from '@/components/SearchBar.vue'
import Sidebar from '@/components/Sidebar.vue'
import TableHead from '@/components/TableHead.vue'
import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
// import 'bootstrap'
import './assets/theme/js/jquery.min.js'
import { createApp } from 'vue'
import App from './App.vue'
import './assets/theme/theme.scss'
import './assets/css/styles.scss'
import router from './router'
import paths from './router/paths'
import money from 'v-money3'
import { EventBus } from './utils/EventBus'
import './utils/Prototypes/prototypeFunctions'
import UserService from './services/UserService'
import { store } from './store'
import OrderService from './services/OrderService'
import BannerService from './services/BannerService'
import ExamService from './services/ExamService'
import Tooltip from '@/components/Tooltip.vue'
import MoneyInput from './components/FormComponents/MoneyInput.vue'
import SettingsService from './services/SettingsService'
import AppealService from './services/AppealService'
import CategorySevice from './services/CategorySevice'
import GroupService from './services/GroupService'
import ApprovedService from './services/ApprovedService'
import TestimonyService from './services/TestimonyService'

const app = createApp(App)
app.config.globalProperties.$eventBus = EventBus
app.config.globalProperties.$userService = new UserService()
app.config.globalProperties.$appealService = new AppealService()
app.config.globalProperties.$orderService = new OrderService()
app.config.globalProperties.$bannerService = new BannerService()
app.config.globalProperties.$categoryService = new CategorySevice()
app.config.globalProperties.$examService = new ExamService()
app.config.globalProperties.$approvedService = new ApprovedService()
app.config.globalProperties.$groupService = new GroupService()
app.config.globalProperties.$testimonyService = new TestimonyService()
app.config.globalProperties.$settingsService = new SettingsService()
app.config.globalProperties.$paths = paths

app.component('Sidebar', Sidebar)
app.component('Navbar', Navbar)
app.component('LoadingContainer', LoadingContainer)
app.component('EmptyState', EmptyState)
app.component('SimpleEmptyState', SimpleEmptyState)
app.component('Container', Container)
app.component('SearchBar', SearchBar)
app.component('TextInput', TextInput)
app.component('MoneyInput', MoneyInput)
app.component('EditorInput', EditorInput)
app.component('SelectInput', SelectInput)
app.component('TextAreaInput', TextAreaInput)
app.component('PasswordInput', PasswordInput)
app.component('FormButton', FormButton)
app.component('TableHead', TableHead)
app.component('ModalComponent', ModalComponent)
app.component('Header', Header)
app.component('ConfirmationAlert', ConfirmationAlert)
app.component('HomeCard', HomeCard)
app.component('ImageCropper', ImageCropper)
app.component('FileUploader', FileUploader)
app.component('YoutubeInput', YoutubeInput)
app.component('AutocompleteInput', AutocompleteInput)
app.component('AutocompleteSelectInput', AutocompleteSelectInput)
app.component('Tooltip', Tooltip)
app.config.globalProperties.$paths = paths

app
    .use(store)
    .use(money)
    .use(router)
    .mount('#app')
