import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", { id: _ctx.triggerId }, [
      _renderSlot(_ctx.$slots, "button")
    ], 8, _hoisted_1),
    _createElementVNode("div", {
      id: _ctx.contentId,
      class: "d-none"
    }, [
      _renderSlot(_ctx.$slots, "content")
    ], 8, _hoisted_2)
  ], 64))
}