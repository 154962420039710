import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  key: 0,
  class: "form-label text-muted"
}
const _hoisted_3 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 0,
  disabled: "",
  value: ""
}
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("i", _hoisted_3, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("select", {
      disabled: _ctx.readOnly,
      class: _normalizeClass(["form-select", { 'is-invalid': _ctx.model.$error }]),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.$model) = $event)),
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChange($event)))
    }, [
      (_ctx.placeholder)
        ? (_openBlock(), _createElementBlock("option", _hoisted_5, _toDisplayString(_ctx.placeholder), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, index) => {
        return (_openBlock(), _createElementBlock("option", {
          key: 'option_' + index,
          value: item[_ctx.propertyForValue]
        }, _toDisplayString(item[_ctx.propertyForText]), 9, _hoisted_6))
      }), 128))
    ], 42, _hoisted_4), [
      [_vModelSelect, _ctx.model.$model]
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("small", null, _toDisplayString(_ctx.errorMsg), 1)
    ])
  ]))
}